.container {
  text-align: center;
  padding-bottom: 1px;
  background-color: white;
}

.inner {
  background-color: black;
  margin: 0 auto 40px;
  width: 500px;
  padding: 32px 0 48px;
  color: white;
  box-shadow: 5px 10px 20px black;
}

.cat {
  width: 100%;
}

.link {
  composes: whitelink from 'css/text.module.scss';
}

@media screen and (max-width: 480px) {
  .inner {
    width: 100vw;
  }
}
