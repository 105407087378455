.cursor {
    width: 51px;
    height: 51px;
    border: 2px solid black;
    border-radius: 50%;
    position: fixed;
    // transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
    
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: rgba(255, 255, 255, 1);
    
    /* background-color: white; */
    mix-blend-mode: difference;
    z-index: 999;
    /* backdrop-filter: grayscale; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .mouseDown {
    width: 29px;
    height: 29px;
  }

  /* #line-h {
    border: 1px solid green;
  }

  #line-v {
    border: 1px solid purple;
  }

  #vertical {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #horizontal {
    position: absolute;
  } */

  .cross {
    padding-left: 3px;
    padding-bottom: 1px;
    font-size: 20px;
  }

  @media screen and (max-width: 500px) {
    .cursor {
      visibility: hidden;
    }
  }