.cardTitle {
  font-size: 24px;
  padding: 0 20px;
  margin-top: 0px;
  margin-bottom: 8px;
}

.cardDescription {
  font-size: 16px;
  margin: 16px;
}

.cardFine {
  font-size: 13px;
  margin-top: 0px;
  padding: 0px 40px 0px;
}

.whitelink {
  color: white;
  text-decoration: none;
}

.whitelink:hover {
  text-decoration: underline;
}

.blacklink {
  color: black;
  text-decoration: none;
}

.blacklink:hover {
  text-decoration: underline;
}
