.pageContainer {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  max-width: 100%;
}

.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
  background-color: white;
  background-size: auto 75%;
  background-repeat: no-repeat;
  // background-position: center center;
  background-position: top 20px center;
  width: 45vh;
  // max-width: 150px;
  max-width: 225px;
  height: 85vh;
  // max-height: 285px;
  max-height: 425px;
  will-change: transform;
  border-radius: 10px;
  box-shadow:
    0 12.5px 75px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.descriptionContainer {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: 85%;
  padding: 0 15px;
  height: 65px;
  padding-bottom: 10px;
}

.title {
  composes: unselectable from "css/properties/unselectable.module.scss";
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 1px;
}

.description {
  composes: unselectable from "css/properties/unselectable.module.scss";
  margin: 0;
  margin-top: 5px;
  font-size: 9px;
  letter-spacing: 1px;
}

.link {
  composes: blacklink from "css/text.module.scss";
  text-decoration: underline;
  font-size: 8px;
  margin-top: auto;
  letter-spacing: 1px;
}

@media screen and (min-width: 480px) {
  .deck > div {
    max-width: 250px;
    max-height: 450px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .descriptionContainer {
    height: 70px;
  }

  .title {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }

  .description {
    margin: 0;
    margin-top: 5px;
    font-size: 9px;
    letter-spacing: 1px;
  }

  .link {
    font-size: 10px;
    margin-top: auto;
    letter-spacing: 1px;
  }
}
