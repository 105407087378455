.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.oponn {
  font-size: 15px;
}

.linksContainer {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  justify-content: space-evenly;
}

.headerLink {
  text-decoration: none;
}

.headerLink, .activeLink {
  font-size: 10px;
  color: black;
  flex-shrink: 1;
  flex-grow: 1;
  transition: flex-grow 1000ms linear;
  text-align: center;

  .outbound {
    transform: scale(0.5) translate(-60%, -70%);
    position: absolute;
  }
  

  &:hover {
    -webkit-text-stroke-width: 1px;

    .outbound {
      -webkit-filter: drop-shadow(1px 0 5px rgba(0, 0, 0, 1));
      filter: drop-shadow(1px 0 5px rgba(0, 0, 0, 1));
    }
  }
}

.activeLink {
  -webkit-text-stroke-width: 1px;
  text-decoration: underline;
}

.lineContainer {
  width: 95%;
}

.blackLine {
  border-color: black white white;
  border-width: 1px;
  margin: 20px auto 0px;
}

@media screen and (min-width: 480px) {
  .headerLink {
    font-size: 16px;
  }
  .oponn {
    font-size: 32px;
  }
}
